@import '~@sas-te/alfabeto-tokens/src/scss/mixins'
@import 'media'

=anim($duration)
  transition: all $duration ease-in-out

// Box
=box($Z)
  border-radius: 8px
  background-color: white
  box-sizing: border-box
  backface-visibility: hidden

  @if $Z == 100
    border: 1px solid transparentize($color-ink-light, 0.6)
    box-shadow: 0px 2px 6px -1px rgba(50, 55, 68, 0.48)

  @if $Z == 200
    box-shadow: 0px 6px 8px -1px rgba(23, 43, 77, 0.32)

  @if $Z == 300
    box-shadow: 0px 10px 16px -2px rgba(23, 43, 77, 0.32)

  @if $Z == 500
    box-shadow: 0px 24px 32px -6px rgba(23, 43, 77, 0.32)

=mq($size)
  @media only screen and (max-width: $size)
    @content

=mq_modal
  @media only screen and (max-width: $device_m), (max-height: $device_s)
    @content

=mq_review_modal
  @media only screen and (max-width: $device_m), (max-height: $device_m)
    @content

=mq_landscape
  @media (orientation: landscape) and (max-width: $device_xs), (orientation: landscape) and (max-height: $device_s)
    @content

=pseudo($display: block, $pos: absolute, $content: '')
  content: $content
  display: $display
  position: $pos

=flex-space-between
  display: flex
  align-items: center
  justify-content: space-between

=flex_sb
  display: flex
  align-items: center
  justify-content: space-between
