@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"


























































.tutorial-modal
  display: flex
  flex-direction: column
  align-items: center
  width: 336px
  position: fixed
  z-index: 1000
  bottom: 16px
  right: 16px
  text-align: center
  transition: all 200ms ease-in-out
  pointer-events: all !important
  transform-origin: bottom right

  +mq-s
    width: auto
    bottom: 16px
    right: 16px
    left: 16px
    padding: 16px !important

  +mq_landscape
    width: 280px
    bottom: 8px
    right: 16px
    left: unset
    padding: 16px !important

  &__title
    margin-bottom: 16px

  &__description
    color: $color-ink-light

  &__image
    margin-bottom: 24px

    +mq-s
      display: none

    +mq_landscape
      display: none

  &__actions
    margin-top: 24px

    .btn ~ .btn
      margin-left: 8px

  &__exit
    position: absolute !important
    top: 16px
    right: 16px
    color: $color-ink-lighter !important

    +mq-s
      display: none

    +mq_landscape
      display: none

  &-enter,
  &-leave-to
    opacity: 0

  &-enter-active
    animation: show-in 700ms ease-in-out

  &-leave-active
    animation: show-out 400ms ease-in-out

@keyframes show-in
  0%
    opacity: 0
    transform: scale(0)

  50%
    transform: scale(1.08)

  100%
    opacity: 1
    transform: scale(1)

@keyframes show-out
  0%
    opacity: 1
    transform: scale(1)
  100%
    opacity: 0
    transform: scale(0)

